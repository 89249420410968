* {
  list-style: none;
   box-sizing: border-box;
   text-decoration: none;
   overflow-x: hidden;
   scroll-behavior: smooth;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}
body{
  background-color: whitesmoke;
  min-width: 320px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
